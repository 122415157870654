import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { Layout } from '../components'
import { container, padding, bgImage } from '../styles/global'
import { media } from '../styles/utils'
import { parseACF } from '../utils'
import { useMount } from 'react-use'

const Artists = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages')
    console.log(data)

    const renderArtists = () => {
        if (!data.artists) return

        const items = data.artists.map((item, i) => {
            return (
                <Item
                    key={i}
                    as={item.link && ExternalLink}
                    href={item.link && item.link}
                    target={item.link && '_blank'}
                >
                    {item.name}
                </Item>
            )
        })

        return <Items>{items}</Items>
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                <Container>
                    <Heading>All Artists</Heading>
                    <Description>
                        Click to link through to their bandcamp, or soundcloud,
                        or suprise journey
                    </Description>
                    {renderArtists()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Item = styled.div``
const Label = styled.div``
const ExternalLink = styled.a``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 112px;
    padding-bottom: 200px;

    ${media.phone`
        padding-top: 32px;
        padding-bottom: 64px;
    `}
`

const Container = styled.div`
	${container}
	${padding}

    ${Heading} {
        display: block;
        font-family: 'Neue Haas', sans-serif;
        font-size: 86.8922px;
        line-height: 97.7%;

        ${media.phone`
            font-size: 44px;
            line-height: 97.7%;
        `}
    }

    ${Description} {
        margin-top: 22px;
        font-family: Courier;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;

        ${media.phone`
            font-size: 14px;
        `}
    }
`

// Artists

const Items = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 27px;

    ${Item} {
        font-family: 'Neue Haas', sans-serif;
        font-size: 86px;
        line-height: 110.2%;
        color: black;

        ${media.phone`
            font-size: 44px;
        `}

        &:hover {
			font-style: italic;
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "about" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Artists
